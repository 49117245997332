// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-document-js": () => import("../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-pages-academy-js": () => import("../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-collections-js": () => import("../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-documents-js": () => import("../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-more-js": () => import("../src/pages/more.js" /* webpackChunkName: "component---src-pages-more-js" */),
  "component---src-pages-themes-js": () => import("../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */)
}

