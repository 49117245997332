module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hesambayatcom","accessToken":"MC5YZUFhZkJFQUFES3dLbUFV.ECZ-K--_vWYE77-977-977-977-9eO-_ve-_vW_vv73vv73vv70677-977-9Gu-_ve-_ve-_vXHvv73vv73vv73vv73vv73vv70","path":"/preview","previews":false,"pages":[{"type":"Post","match":"/:uid","path":"/post","component":"/Users/hsmbyt/Projects/hesambayat.com/src/templates/post.js"},{"type":"Document","match":"/doc/:uid","path":"/doc","component":"/Users/hsmbyt/Projects/hesambayat.com/src/templates/document.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"static/meow-party.gif","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-64346230-9","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"hesambayat.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
